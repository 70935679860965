<template>
    <el-container>
        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>在线制版平台</el-breadcrumb-item>
                <el-breadcrumb-item>业务数据</el-breadcrumb-item>
                <el-breadcrumb-item>DKDM</el-breadcrumb-item>
            </el-breadcrumb>
            <SearchForm v-if="searchOptions.open" v-model="searchOptions.searchForm" :items="searchOptions.items" @search="handleSearch" :params="params">
            </SearchForm>
            <template v-if="tableOptions.open">
                <el-table :data="tableOptions.tableData" stripe border size="small"
                           header-cell-class-name="table_header"
                          cell-class-name="table_cell" style="width: 100%">
                    <el-table-column type="index" label="序号" width="50"/>
                    <template v-if="tableOptions.items">
                        <template v-for="item in tableOptions.items">
                            <el-table-column v-if="item.type === Search_Type.Select" :label="item.label">
                                <template slot-scope="scope">
                                    <template v-if="item.props">
                                        <template v-if="item.props[scope.row[item.name]]">
                                            <template v-if="item.propsColors && item.propsColors[scope.row[item.name]]">
                                                <el-tag :type="item.propsColors[scope.row[item.name]]" disable-transitions>
                                                    {{item.props[scope.row[item.name]]}}
                                                </el-tag>
                                            </template>
                                            <template v-else>
                                                {{item.props[scope.row[item.name]]}}
                                            </template>
                                        </template>
                                        <template v-else-if="scope.row[item.name]">
                                            <el-tag type="danger" disable-transitions>
                                                {{scope.row[item.name]}}
                                            </el-tag>
                                        </template>
                                        <template v-else>
                                            {{scope.row[item.name]}}
                                        </template>
                                    </template>
                                    <template v-else>
                                        <el-tag type="warning" disable-transitions>
                                            {{scope.row[item.name]}}
                                        </el-tag>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column v-else-if="item.type === Search_Type.DynamicSelect" :label="item.label">
                                <template slot-scope="scope">
                                    <el-tag :type="g_getDynamicValue(scope.row[item.name],item, true)"
                                            disable-transitions>{{g_getDynamicValue(scope.row[item.name],item)}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column v-else :prop="item.name" :label="item.label"/>
                        </template>
                    </template>
                    <el-table-column min-width="200" label="操作">
                        <template slot-scope="scope">
                            <template v-if="!selector">
                                <template v-if="detailOptions.open">
                                    <el-divider direction="vertical"></el-divider>
                                    <el-button type="text" @click="openDetail(scope.row.id)">
                                        详细 <i class="el-icon-document"></i>
                                    </el-button>
                                </template>
                            </template>
                            <template v-else>
                                <el-button type="text" @click="selectHandle(scope.row)">
                                    选择 <i class="el-icon-check"></i>
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 20, 50, 100]"
                        :current-page="tableOptions.page"
                        :page-size="tableOptions.limit"
                        :total="tableOptions.total">
                </el-pagination>
            </template>
            <!--popup search-->
            <el-dialog :visible.sync="detailPanelVisible" center width="70%" append-to-body :before-close="closeDetailPanel">
                <DetailPanel v-if="detailOptions.open" v-model="detailOptions" :id="detailOptions.id" title="详细信息"></DetailPanel>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
    import SearchForm from "../common/OptionalSearchForm";
    import DetailPanel from "../common/DetailPanel";
    import {Search_Type} from "../../constants";
    const prefix = '/dkdm';
    const getListUrl = `${prefix}/list`;
    const getDetailUrl = (id) => `${prefix}/detail/${id}`
    export default {
        name: "Menu2_Dkdm",
        components: {SearchForm,DetailPanel},
        data(){
            return{
                Search_Type,
                tableOptions:{
                    tableData:[],
                    page: 1,
                    limit: 10,
                    total: 0,
                },
                searchOptions:{
                    searchForm: {},
                    searchParams:{},
                },
                editOptions: {
                },
                detailOptions: {
                    getDetailUrl : getDetailUrl,
                },
                menuVisible: false,
                detailPanelVisible: false,
            }
        },
        props:['params','selector'],
        created() {
            this.g_setupMetadataConfig(this, this.$options.name)
            if(!this.params){
                if(this.tableOptions.items.some(item=>item.type === Search_Type.DynamicSelect)){
                    this.loadDynamicSelectData()
                }else{
                    this.getTableData();
                }
            }
        },
        methods: {
            loadDynamicSelectData(){
                let needLoad = true;
                //加载动态键值对
                this.tableOptions.items.filter(item=>item.type === Search_Type.DynamicSelect).forEach(item=>{
                    let dynamicUrl = item.dynamicUrl;
                    if(!dynamicUrl || item.props) return;
                    needLoad = false
                    this.$axios.get(dynamicUrl).then(res=>{
                        try {
                            let params = item.dynamicParams.split(',')
                            if(params.length !== 2) throw 'error parameter num'
                            item.p1 = params[0]
                            item.p2 = params[1]
                            item.props = res.data
                        }catch (e) {
                            console.log(e)
                        }
                        this.getTableData()
                    })
                })
                if(needLoad) this.getTableData()
            },
            getTableData(){
                this.$axios.get(getListUrl,{
                    params: {
                        ...this.g_getPage(this.tableOptions.page,this.tableOptions.limit),
                        ...this.searchOptions.searchForm
                    }
                }).then(res=>{
                    this.tableOptions.tableData = res.data.records;
                    this.tableOptions.page = res.data.current;
                    this.tableOptions.limit = res.data.size;
                    this.tableOptions.total = res.data.total;
                })
            },
            handleSizeChange(limit){
                this.tableOptions.limit = limit;
                this.getTableData();
            },
            handleCurrentChange(page){
                this.tableOptions.page = page;
                this.getTableData();
            },
            handleSearch(){
                this.tableOptions.page = 1;
                this.getTableData();
            },
            closeMenu(done){
                this.searchOptions.searchParams = []
                done();
            },
            openMenu(id){
                this.menuVisible = true;
                this.searchOptions.searchParams = [{name:'id',value:id}];
            },
            openDetail(id){
                this.detailOptions.id = id;
                //this.detailOptions.initData = row
                this.detailPanelVisible = true
            },
            closeDetailPanel(){
                this.detailOptions.id = null
                this.detailPanelVisible = false
            },
            selectHandle(row){
                this.$emit('onselect', row)
            },
        }
    }
</script>

<style scoped>

</style>